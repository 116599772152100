import React, { useEffect, useRef } from 'react';
import './Sidebar.css'; // Import CSS for styling
import { useNavigate } from 'react-router-dom';
import ThemeSwitcher from '../../ThemeSwitcher/ThemeSwitcher';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        toggleSidebar();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, toggleSidebar]);

  return (
    <div ref={sidebarRef} className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="close-btn" onClick={toggleSidebar}>
        &times;
      </button>
      <h2>Dashboard</h2>
      <ul>
        <li onClick={() => navigate("/")} style={{ cursor: 'pointer' }}>Home</li>
        <li onClick={() => navigate("/Apps")} style={{ cursor: 'pointer' }}>Apps</li>
        <li onClick={() => navigate("/About")} style={{ cursor: 'pointer' }}>About</li>
        <li className="themeswitcher"><ThemeSwitcher/></li>
      </ul>
    </div>
  );
};

export default Sidebar;
