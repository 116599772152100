import React, { useState, useRef, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Login from './Login/Login';
import Register from './Register/Register';
import './Authentication.css';

console.log("Entering Authentication component");

const Authentication = () => {
    const [isLogin, setIsLogin] = useState(true);
    const [containerHeight, setContainerHeight] = useState('auto');
    const containerRef = useRef(null);
    const loginRef = useRef(null);
    const registerRef = useRef(null);

    const showLogin = () => {
        console.log("showLogin function called"); 
        setIsLogin(true);
    };

    const showRegister = () => {
        console.log("showRegister function called");
        setIsLogin(false);
    };

    const updateHeight = () => {
        console.log("updateHeight function called");
        if (isLogin && loginRef.current) {
            console.log("Setting container height based on loginRef");
            setContainerHeight(loginRef.current.scrollHeight + 'px');
        } else if (!isLogin && registerRef.current) {
            console.log("Setting container height based on registerRef");
            setContainerHeight(registerRef.current.scrollHeight + 'px');
        }
        
        setTimeout(() => {
            console.log("Resetting container height to auto");
            setContainerHeight('auto');
        }, 300);
    };

    useEffect(() => {
        console.log("useEffect called");
        

        updateHeight();
        window.addEventListener('resize', updateHeight);
        console.log("Added resize event listener");

        return () => {
            console.log("Cleaning up resize event listener");
            window.removeEventListener('resize', updateHeight);
        };
    }, [isLogin]);



    return (
        <div className="authentication">
            <div className="auth-buttons">
                <button onClick={showLogin} className={'login ' + (isLogin ? 'active' : 'inactive')}>
                    Login
                </button>
                <button onClick={showRegister} className={'register ' + (!isLogin ? 'active' : 'inactive')}>
                    Register
                </button>
            </div>
            <div className="auth-content" style={{ height: containerHeight }} ref={containerRef}>
                <TransitionGroup component={null}>
                    <CSSTransition
                        key={isLogin ? 'login' : 'register'}
                        timeout={300}
                        classNames="fade"
                        onEnter={(node) => {
                            console.log('onEnter', node);
                            setContainerHeight(node.scrollHeight + 'px');
                        }}
             
                        onExit={(node) => {
                            console.log('onExit', node);
                            // setContainerHeight(node.scrollHeight + 'px');
                        }}
             
                    >
                        {isLogin ? <Login key="login" ref={loginRef} /> : <Register key="register" setIsLogin={setIsLogin} ref={registerRef} />}
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </div>
    );
};

console.log("Exiting Authentication component");

export default Authentication;