// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './components/Authentifcation/AuthContext/AuthContext';

const ProtectedRoute = ({ children }) => {
    const { token } = useAuth();

    if (!token) {
        // Si l'utilisateur n'est pas connecté, redirige vers la page de connexion
        return <Navigate to="/auth" />;
    }

    // Sinon, affiche le composant enfant (page protégée)
    return children;
};

export default ProtectedRoute;
