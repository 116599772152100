import React, { useEffect, useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import './BottomDrawer.css';
import ThemeSwitcher from '../../ThemeSwitcher/ThemeSwitcher';
import { useNavigate } from 'react-router-dom';

const BottomDrawer = ({ isOpen, toggleDrawer }) => {
  const navigate = useNavigate();
  const drawerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        toggleDrawer();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, toggleDrawer]);

  return (
    <div ref={drawerRef} className={`bottom-drawer ${isOpen ? 'open' : ''}`}>
      <div className="drawer-content">
        <ul>
          <li onClick={() => navigate("/")} style={{ cursor: 'pointer' }}>Home</li>
          <li onClick={() => navigate("/Apps")} style={{ cursor: 'pointer' }}>Apps</li>
          <li onClick={() => navigate("/About")} style={{ cursor: 'pointer' }}>About</li>
          <li className="themeswitcher"><ThemeSwitcher/></li>
        </ul>
      </div>
    </div>
  );
};

export default BottomDrawer;
