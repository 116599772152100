import React, { useEffect, useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import './TopDrawer.css';
import { useNavigate } from 'react-router-dom';
import { IoLogOut } from "react-icons/io5";
import { useAuth } from '../../../Authentifcation/AuthContext/AuthContext';
import { useLocation } from 'react-router-dom';
import { IoMdSettings } from "react-icons/io";

const TopDrawer = ({ isOpen, toggleDrawer }) => {
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const { logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const drawerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        toggleDrawer();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, toggleDrawer]);

  return (
    <div ref={drawerRef} className={`top-drawer ${isOpen ? 'open' : ''}`}>
      <div className="drawer-content">
        <div className='item-container account'>
          <div className='item'>
            <div style={{ textAlign: "left" }}>
              <p>{currentUser.name}</p>
              <p style={{ fontStyle: "italic", fontSize: "small" }}>{currentUser.email}</p>
            </div>
            <div className="profile" onClick={toggleDrawer}>
              <img src={currentUser.profilePictureUrl} alt="Profile" className="profile-image" />
            </div>
          </div>
        </div>
        <div className='item-container account-parameters'>
          <div className='item logout-container'>
            <IoMdSettings size={25} />

            <p>Account settings</p>
          </div>
          <div className='item logout-container' onClick={() => { logout(); window.location.reload(); }}>
            <IoLogOut color='#f54242' size={25} />

            <p>Logout</p>
          </div>

        </div>
      </div>
    </div>
  );
};

export default TopDrawer;
