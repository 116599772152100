import React, { useState } from 'react';
import api from '../../../api';
import { useNotification } from '../../NotificationProvider/NotificationProvider'; // Remplacez par votre bibliothèque de notifications
import { useAuth } from '../AuthContext/AuthContext'; // Remplacez par votre contexte d'authentification
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { MdCheckBoxOutlineBlank, MdCheckBox  } from "react-icons/md";


const Login = ({ setIsLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { addNotification } = useNotification();
    const { login } = useAuth();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);


    const handleLogin = (e) => {
        e.preventDefault(); // Empêche le rechargement de la page lors de la soumission du formulaire

        api.post(`/auth/login`, {
            email,
            password
        })
            .then(response => {
                console.log(response.data);
                login(response.data.token, response.data.user);
                addNotification('Login successful!', 'success');
                navigate("/");
            })
            .catch(error => {
                console.error(error);
                addNotification('Invalid credentials!', 'error');
            });
    };

    return (
        <form className="login" onSubmit={handleLogin}>
            <label htmlFor="email">Email:</label>
            <input
                type="email"
                placeholder="john.doe@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="password">Password:</label>
            <input
                type={showPassword ? 'text' : 'password'}
                placeholder={showPassword ? 'p4sSw04d' : '********'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <div className='registerInput' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {showPassword ? <MdCheckBox size={20} onClick={() => setShowPassword(!showPassword)} /> : <MdCheckBoxOutlineBlank size={20} onClick={() => setShowPassword(!showPassword)} />}
                <label htmlFor="showPassword">Show Password</label>
            </div>
            <button type="submit">Login</button>
        </form>
    );
};

export default Login;