import React, { useState, useEffect } from "react";
import "./Account.css";


function Account() {
    const currentUser = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        document.title = "My Account";
    }, []);


    return (
        <div className="account">
            <div className="account-header">
                <div className="account-title">
                <div className="profile">
                    <img src={currentUser.profilePictureUrl} alt="Profile" className="profile-image" />
                </div>
                <div className="profile-info">
                    <h2>{currentUser.name}</h2>
                    <p>{currentUser.email}</p>
                </div>
                </div>
                <div className="profile-actions">
                    <button className="btn btn-primary">Edit</button>
                </div>
 
            </div>
        </div>
    );
}

export default Account;