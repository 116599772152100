import React, { useEffect } from 'react';
import NetworkDevices from '../components/NetworkDevices/NetworkDevices';

function NetworkPage() {

    useEffect(() => {
        document.title = "Aynos - Network";
    }, []);
    
    return (
            <NetworkDevices />

    );
}

export default NetworkPage;
