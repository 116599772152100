// src/Routes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AppsPage from './pages/AppsPage';
import AboutPage from './pages/AboutPage';
import NetworkPage from './pages/NetworkAppPage';
import LoginPage from './pages/AuthPage';
import ProtectedRoute from './ProtectedRoute';
import AccountPage from './pages/AccountPage';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/Apps" element={<ProtectedRoute><AppsPage /></ProtectedRoute>} />
      <Route path="/About" element={<AboutPage />} />
      <Route path="/Network" element={<ProtectedRoute><NetworkPage /></ProtectedRoute>} />
      <Route path="/auth" element={<LoginPage />} />
      <Route path="/me" element={<AccountPage />} />

    </Routes>
  );
}

export default AppRoutes;
