import React, { useState, useEffect } from 'react';
import './Header.css';
import aynosLogo from '../../../images/logo.png';
import { useTheme } from '../../../ThemeContext';
import { GiHamburgerMenu } from "react-icons/gi";
import { LuLogIn } from "react-icons/lu";
import { Link, useNavigate } from 'react-router-dom';


function Header({ toggleSidebar, isMobile, isSidebarVisible, toggleTopDrawer }) {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const { theme, updateTheme } = useTheme();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setIsHeaderVisible(false); // Cache le header au scroll vers le bas
      } else {
        setIsHeaderVisible(true); // Affiche le header au scroll vers le haut
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);


  let headerClasses = 'header ' + (isHeaderVisible ? 'visible' : 'hidden');



  return (
    <div className={headerClasses}>
      <div className='left-header'>
        {!isMobile && <button
          onClick={toggleSidebar}
          className={`toggle-sidebar-btn ${isSidebarVisible ? 'rotated' : ''}`}
        >
          <GiHamburgerMenu />
        </button>}
        <div className="title" onClick={() => navigate("/")} style={{ cursor: 'pointer' }}
        >

          <img src={aynosLogo} alt="Aynos Logo" className={`logo ${theme}`} />
          <h1 className="title">DASHBOARD</h1>

        </div>
      </div>
      <div className='right-header'>
        {token ? 
          <div className="profile" onClick={toggleTopDrawer}>
            <img src={currentUser.profilePictureUrl} alt="Profile" className="profile-image" />
          </div>
         : 
        <div style={{display: 'flex' ,gap:  '20px'} }><LuLogIn style={{ cursor: 'pointer' }} size={30} onClick={() => navigate("/auth")}/></div>}
      </div>
    </div>
  );
}


export default Header;
