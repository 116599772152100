import React, { useState, useEffect } from 'react';
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import Navbar from './Navbar/Navbar'; // Ajouter votre Navbar
import BottomDrawer from './BottomDrawer/BottomDrawer'; // Ajouter votre BottomDrawer
import { useLocation } from 'react-router-dom';
import TopDrawer from './Header/TopDrawer/TopDrawer';


const Navigation = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();
  const [isTopDrawerOpen, setIsTopDrawerOpen] = useState(false);
  const token = localStorage.getItem("token");

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleTopDrawer = () => {
    setIsTopDrawerOpen(!isTopDrawerOpen);
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Gérer la détection de la taille de l'écran (écrans mobiles ou grands écrans)
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true); // On est sur un petit écran (mobile/tablette)
      } else {
        setIsMobile(false); // On est sur un grand écran (PC)
      }
    };

    // Ajoutez un écouteur d'événements pour la redimension de l'écran
    window.addEventListener('resize', handleResize);
    
    // Exécuter une fois au montage pour vérifier la taille initiale
    handleResize();

    return () => {
      // Nettoyez l'écouteur d'événements lors du démontage du composant
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (location.pathname === "/auth") {
    return null;
  }

  return (
    <div className="navigation-container">
      {/* Sidebar pour les grands écrans */}
      {!isMobile && <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />}
      {isMobile && <BottomDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />}
      {/* Navbar pour les petits écrans */}
      {token && <TopDrawer isOpen={isTopDrawerOpen} toggleDrawer={toggleTopDrawer}/>}
      {isMobile && <Navbar toggleDrawer={toggleDrawer} />}
      {/* Header (affiché de façon constante en haut) */}
      <Header toggleSidebar={toggleSidebar} isMobile={isMobile} isSidebarVisible={isSidebarOpen} toggleTopDrawer={toggleTopDrawer} />
    </div>
  );
};

export default Navigation;
