// src/App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './Routes';
import './App.css';
import Navigation from './components/Navigation/Navigation';
import ParticleBackground from './components/AnimatedBackground/AnimatedBackground';

function App() {
  return (
    <div className="app">
      <Router>
        <Navigation />
        <div className="main-content">
          <AppRoutes />
        </div>
      </Router>
      <ParticleBackground />
    </div>
  );
}

export default App;
