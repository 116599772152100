import React, { useEffect } from 'react';
import './HomePage.css';
import Authentication from '../components/Authentifcation/Authentication';

function HomePage() {

    useEffect(() => {
        document.title = "Aynos - Login";
    }, []);
    
    return (
        <Authentication/>
    );
}

export default HomePage;
