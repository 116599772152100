import React from 'react';
import './PublicHome.css';
import { FaPlus } from "react-icons/fa";

function PublicHome() {
  return (
    <div className="public-home">
       
        <h1>Bienvenue sur mon site!</h1>
        <p>Il n'y a pas grand chose à faire quand on n'a pas de compte :) </p>
      
      
    </div>
  );
}

export default PublicHome;
