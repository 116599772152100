import React, { useEffect } from 'react';
import Dashboard from '../components/Dashboard/Dashboard';
import './HomePage.css';
import { AuthProvider, useAuth } from '../components/Authentifcation/AuthContext/AuthContext';
import PublicHome from '../components/PublicHome/PublicHome';

function HomePage() {
    const { token } = useAuth();

    useEffect(() => {
        document.title = "Aynos - Dashboard";
    }, []);
    
    return token ? <Dashboard/> : <PublicHome/>
}

export default HomePage;
