import React, { useEffect, useState } from 'react';
import './NetworkDevices.css';
import NetworkDevice from './NetworkDevice/NetworkDevice';
import { TbReload } from "react-icons/tb";
import { useNotification } from '../NotificationProvider/NotificationProvider';
import api from '../../api';


const NetworkDevices = () => {
    const [devices, setDevices] = useState([]);
    const [filter, setFilter] = useState("all");
    const { addNotification } = useNotification();
    

    const fetchDevices = async () => {
        try {
            const response = await api.get(`/network/getAllDevices`);
            setDevices(response.data);
        } catch (error) {
            console.error("Error fetching devices:", error);
            addNotification(`Error fetching devices:\n${error}`, 'error');
        }
    };

    useEffect(() => {
        

        fetchDevices();
    }, []);

    const [filteredDevices, setFilteredDevices] = useState([]);

    useEffect(() => {
        setFilteredDevices(devices);
    }, [devices]);

    const handleFilterChange = () => {
        if (filter === 'all'){
            setFilter('connected');
            setFilteredDevices(devices.filter(device => device.connected === true));

        }else if (filter === 'connected'){
            setFilter('disconnected');
            setFilteredDevices(devices.filter(device => device.connected === false));

        } else if (filter === 'disconnected'){
            setFilter('all');
            setFilteredDevices(devices);

        }
    };




    return (
        <div className='network-devices-container'>
            <h1>Network Devices</h1>
            <div className="filters">
            <div className={`filter ${filter}`} onClick={handleFilterChange}></div>
            <TbReload size={25} onClick={() => {fetchDevices();addNotification("Reloading network devices")}}/>
            </div>
            <ul className="network-devices">
                {filteredDevices.map((device) => (
                    <li key={device.ip}>
                        <NetworkDevice name={device.hostname} status={device.connected} ip={device.ip} mac={device.mac} last_seen={device.lastSeen} />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default NetworkDevices;