import React, { useEffect } from 'react';
import './AccountPage.css';
import "../components/NetworkDevices/NetworkDevices";
import Account from "../components/Account/Account";
function AccountPage() {
  useEffect(() => {
    document.title = "";
  }, []);

  return (
<div className="about-page">
      <Account />
</div>
  );
}

export default AccountPage;
