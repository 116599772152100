import React, { useState } from 'react';

import './Register.css';
import axios from 'axios';
import api from '../../../api';
import { useNotification } from '../../NotificationProvider/NotificationProvider';
import { MdCheckBoxOutlineBlank, MdCheckBox  } from "react-icons/md";


const Register = ({ setIsLogin }) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const { addNotification } = useNotification();

    const [showPassword, setShowPassword] = useState(false);

    const handleRegister = (e) => {
        e.preventDefault(); // Empêche le rechargement de la page lors de la soumission du formulaire
        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match!');
            return;
        }

        api.post(`/auth/signup`, {
            username,
            email,
            password
        })
            .then(response => {
                console.log(response.data);
                addNotification('Registration successful!', 'success');
                setTimeout(() => {
                    setIsLogin(true);
                }, 1000);
            })
            .catch(error => {
                console.error(error);
                addNotification(`Registration failed!\nerror:${error}`, 'error');
            });

    };

    const validatePassword = (password) => {
        if (password.length < 8) {
            setPasswordError('Password must be at least 8 characters long.');
        } else if (!/[A-Z]/.test(password)) {
            setPasswordError('Password must contain at least one uppercase letter.');
        } else if (!/[a-z]/.test(password)) {
            setPasswordError('Password must contain at least one lowercase letter.');
        } else if (!/[0-9]/.test(password)) {
            setPasswordError('Password must contain at least one number.');
        } else {
            setPasswordError('');
        }
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        validatePassword(newPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        if (newConfirmPassword !== password) {
            setConfirmPasswordError('Passwords do not match!');
        } else {
            setConfirmPasswordError('');
        }
    };

    return (
        <form className="register" onSubmit={handleRegister}>
            <div className='registerInput'>
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    placeholder="john.doe@email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className='registerInput'>
                <label htmlFor="username">Username:</label>
                <input
                    type="text"
                    placeholder="John Doe"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </div>
            <div className='registerInput'>
                <label htmlFor="password">Password:</label>
                <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder={showPassword ? 'p4sSw04d' : '********'}
                    value={password}
                    onChange={handlePasswordChange}
                />
                {passwordError && <div className="error">{passwordError}</div>}
            </div>
            <div className='registerInput'>
                <label htmlFor="confirmPassword">Confirm Password:</label>
                <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder={showPassword ? 'p4sSw04d' : '********'}
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                />
                {confirmPasswordError && <div className="error">{confirmPasswordError}</div>}
            </div>
            <div className='registerInput' style={{display: 'flex', flexDirection: 'row',alignItems: 'center'}}>
            {showPassword ? <MdCheckBox size={20} onClick={() => setShowPassword(!showPassword)} /> : <MdCheckBoxOutlineBlank size={20} onClick={() => setShowPassword(!showPassword)}/>}
                <label htmlFor="showPassword">Show Password</label>
            </div>
            <button type="submit" disabled={passwordError || confirmPasswordError}>Register</button>
        </form>
    );
};

export default Register;